.icon {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    overflow: hidden;
}

.building-icon {
    background: radial-gradient(rgb(185, 185, 185), rgb(32, 28, 59));
}

.unit-icon {
    background: radial-gradient(rgb(189, 197, 224), rgb(139, 147, 243));
}

.faction-enemy .unit-icon {
    background: radial-gradient(rgb(218, 201, 201), rgb(233, 131, 131));
}

