.faction-enemy .node-title {
    color: red;
    font-style: italic;
}

.unassigned-workers {
    text-align: right;
    font-size: small;
    display: none;
}

.faction-player .unassigned-workers {
    display: block;
}