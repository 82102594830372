.buildSlot {
    border: 1px solid hsl(0 0% 80%);
    border-radius: 5px;
    margin: -1px;
    overflow: hidden;
}

.buildSlot-selected {
    border-color: hsl(0 0% 80%);
    box-shadow: 0 0 8px hsl(0 0% 20%);
}

.construction {
    position: relative;
    display: grid;
    grid-template-rows: 64px;
}
.construction .building-icon {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    filter: contrast(0.2) brightness(1.5);
}
.construction-status {
    justify-self: center;
    align-self: center;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 8px rgba(255, 255, 255, 1.0);
    font-size: x-small;
    margin: 8px;
}

.mine {
    position: relative;
    height: 64px;
}
.mine .building-icon {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    height: 50%;
    width: 50%;
}
.mine-status {
    position: absolute;
    top: 50%;
    height: 50%;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 8px rgba(255, 255, 255, 1.0);
    font-size: small;
    margin: 8px;
}

.building {
    position: relative;
}

.building.building-inactive .building-icon {
    filter: grayscale(1) brightness(0.666);
    z-index: 0;
}
.construction.building-inactive .building-icon {
    filter: grayscale(1) contrast(0.2) brightness(1.0);
    z-index: 0;
}
.building-activation-overlay {
    display: none;
    position: absolute;
    bottom: 4px;
    left: 1px;
    rotate: 90deg;
    text-shadow: 0 0 5px white;
    z-index: 10;
}
.building-inactive .building-activation-overlay {
    display: block;
    color: red;
}
.faction-player .building-active:hover .building-activation-overlay {
    display: block;
    color: hsl(0 0% 50%);
    text-shadow: 0 0 5px white,0 0 5px white;
}
.faction-player .building .building-activation-overlay:hover {
    text-shadow: 0 0 5px white,0 0 5px white,0 0 5px white;
}

.jobs-overlay {
    display: none;
    position: absolute;
    color: white;
    font-size: .7em;
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0 0 2px black;
    top: 2px;
    left: 2px;
    z-index: 5;
}

.faction-player .jobs-overlay {
    display: block;
}
