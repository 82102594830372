.tooltip-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
}

.tooltip-wrapper {
    position: absolute;
    /* border: 1px solid gray; */
    z-index: 100;
}
.tooltip {
    background-color: #333d;
    border-radius: 4px;
    padding: 4px;
    color: white;
    font-size: 0.85em;
    max-width: 30em;
}
.hasTooltip {
    line-height: 0;
}
