body {
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  /* overflow: hidden; */
}

.Popup {
  position: absolute;
}

.top-bar {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  top: 8px;
  left: 8px;
}

.selection-ui {
  top: 60px;
  left: 60px;
  bottom: 60px;
  overflow: hidden;
  pointer-events: none;
}

.selection-ui * {
  pointer-events: all;
}

.main-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: calc(100vw - 2px);
  height: calc(100vh - 2px);
  background-color: black;
  border: 1px solid gray;
}

.main-map>canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.fog-of-war {
  opacity: 50%;
  filter: blur(3px);
}

.time-controls {
  background-color: white;
  color: black;
  padding: 0.2em;
  border-radius: 3px;
  top: 8px;
  right: 8px;
}

.open-ingame-menu {
  right: 0;
  bottom: 0;
}
.open-ingame-menu .button {
  border-right: none;
  border-bottom: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.App.ingame-menu-open {
  filter: grayscale(0.8) brightness(0.5) blur(5px);
}

.App.ingame-menu-open .Popup.selection-ui {
  bottom: unset;
}
