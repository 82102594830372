.unitInTraining {
    text-align: left;
    margin-top: 1em;
}

.trainingQueue {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
}

.trainingQueueNumber {
    margin: .3em;
    /* border: 1px solid rgb(200, 200, 200); */
    background-color: rgb(230, 230, 230);
    border-radius: 3px;
    padding: .2em;
    width: 1em;
}

.unitTypeStats {
    display: grid;
    grid-template-columns: auto auto;
}

.unitTypeStats .stat-value {
    margin-left: 0.1em;
    text-align: right;
    justify-self: stretch;
}

.unitTypeStats .stat-value * {
    display:block;
    line-height: unset;
}

.unitTypeStats .stat-icon {
    justify-self: center;
}

.trainingSelection .purchase-selection-item {
    grid-template-columns: auto 1fr auto;
}