.main-menu {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-content: center;
    gap: 8px;
    text-align: center;
    background-color: black;
    color: white;
    user-select: none;
}

.button {
    border-radius: 4px;
    border: 1px solid hsl(0 0% 20%);
    padding: 4px;
    cursor: default;
    background: black;
    color: white;
    user-select: none;
}

.button[aria-disabled='true'] {
    color: hsl(0 0% 20%);
}

.button:hover:not([aria-disabled='true']) {
    background: hsl(0 0% 20%);
    cursor: pointer;
}

.latest-save-game {
    color: gray;
    font-size: small;
}
