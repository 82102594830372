@import "./MainMenu.css";

.savegames {
    display: grid;
    gap: 1em;
}

.savegame {
    display: grid;
    grid-template-columns: 1fr auto;
}

.load-savegame {
    display: grid;
    grid-template: 
        "icon title title title" auto
        "icon ingame seed real" auto / 2em auto auto auto;
    grid-template-areas: "a" "b c";
    column-gap: 1em;
    position: relative;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    padding-right: .5em;
}
.delete-savegame {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: grid;
    place-items: center;
    padding: .5em;
    transition: color 0.25s;
}
.delete-savegame:hover {
    color: red;
}
.load-savegame .fa-save {
    grid-area: icon;
    align-self: center;
    padding: 0.5em;
}

.load-savegame.autosave .name::after {
    content: "(auto)";
    position: absolute;
    top: .5em;
    right: .5em;
    color: gray;
    font-size: x-small;
}

.load-savegame .name {
    font-weight: bold;
    grid-area: title;
}

.load-savegame .ingame-time {
    grid-area: ingame;
    font-style: italic;
    font-size: small;
    text-align: left;
}

.load-savegame .seed {
    grid-area: seed;
    font-style: italic;
    font-size: small;
}
.load-savegame .seed::before {
    content: "Seed: "
}

.load-savegame .real-time {
    grid-area: real;
    font-style: italic;
    font-size: small;
    text-align: right;
}

.delete-all-savegames {
    text-align: right;
}
.delete-all-savegames .button{
    margin-top: 2em;
    display: inline-block;
    transition: color 0.25s, box-shadow 0.25s;
}
.delete-all-savegames .button:hover:not([aria-disabled="true"]) {
    box-shadow: 0 0 8px darkred;
    color: red;
    background-color: hsl(0 0% 10%);
}

.are-you-sure {
    position: absolute;
    border: 1px solid white;
    background: hsl(0 0% 10%);
    padding: .5em;
    border-radius: 4px;
    transform: translateY(-50%);
    margin-left: .5em;
    box-shadow: 0 0 8px gray;
}

.are-you-sure::before {
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-100%);
    content: " ";
    width: .5em;
    height: 1em;
    background-color: white;
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
}

.are-you-sure .button {
    display: inline-block;
    margin: 0.2em;
}

.placeholder-no-savegames {
    color: gray;
}
