.infoBox {
    color: black;
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    padding-right: 0px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 1fr;
    max-height: 100%;
    box-sizing: border-box;
    max-width: 66.666vw;
}

.infoBox > * {
    scrollbar-width: thin;
}

.infoBox-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
    padding-right: 16px;
}

.infoBox-divider {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: auto;
    height: 100%;
    width: 32px;
    justify-items: center;
}

.infoBox-details {
    height: 100%;
    padding-right: 16px;
    overflow-y: auto;
    overflow-y: overlay;
}
