.purchase-selection {
    text-align: left; 
    margin-top: 1em; 
}

.purchase-selection-item {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 4px;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: gray;
}

.purchase-selection-item > img {
    align-self: start;
}

.purchase-selection-item .purchase-name {
    font-size: larger;
    color: black;
}
