.faction-enemy .platoon-title {
    color:red;
    font-style: italic;
}

.platoon-box {
    overflow-y: auto;
    height: 100%;
}
.platoon-box .MuiCardContent-root {
    height: 100%;
}
.platoon-box .MuiGrid-root.MuiGrid-container {
    height: 100%;
}
.platoon-box .MuiGrid-root.MuiGrid-item {
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 1fr;
}

.platoon-units {
    margin-top: 1em;
}

.platoon-unit-list {
    text-align: left;
    overflow-y: auto;
}

.platoon-unit-list img {
    vertical-align: middle;
}

.platoon-unit-details-title {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 64px auto;
}
.platoon-unit-details .stat-icon {
    margin-left: 1em;
}
.platoon-unit-details .stat-value {
    margin-left: 0.2em;
    margin-right: 1em;
}

.platoon-unit-details > :not(.platoon-unit-details-title) {
    margin-top: 1em;
}
