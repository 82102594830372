.ingame-menu-wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    text-align: center;
    color: white;
}
  
.ingame-menu {
    display: grid;
    gap: 8px;
}

.save-as-menu {
    width: 20em;
}

.save-as-menu .save-as-input {
    display: grid;
    grid: auto / auto 2em;
    align-items: center;
}
.save-as-menu .button-back {
    display: inline-block;
    text-align: center;
    margin-right: 1em;
}
.save-as-menu .save-as-label {
    text-align: left;
}

.save-as-menu input {
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    align-self: stretch;
}
.save-as-menu .button-ok {
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    align-self: stretch;
}

.save-as-menu .save-error {
    min-height: 3em;
    margin-top: 1em;
    color: red;
}
