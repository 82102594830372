.resources {
    display: inline-block;
}
.resource {
    display: inline-block;
    white-space: nowrap;
    padding: 0 0.5em;
}
.resource-icon {
    padding-right: 0.1em;
}