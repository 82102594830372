.build-slot-details {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 1fr;
}

.build-slot-details-title {
    display: grid;
    grid-template-columns: 64px auto 64px;
    align-items: center;
    justify-items: center;
}
.building-details {
    margin-bottom: 1em;
}

.build-slot-details-title-index {
    /* font-family: 'Courier New', Courier, monospace; */
    display: inline-block;
    padding-top: calc(0.25em + 1px);
    padding-right: .7px;
    width: 1.5em;
    height: 1.25em;
    /* padding: 0.2em; */
    justify-self: start;
    border: 1px solid gray;
    line-height: 1em;
    border-radius: 10em;
    cursor: default;
    user-select: none;
    background-color: hsl(0, 0%, 70%);
    color: white;
}

.priority-display {
    width: 1em;
    height: 1em;
    line-height: 1em;
    user-select: none;
    display: inline-block;
}
