.tech-tree {
    overflow: auto;
    height: calc(100vh - 200px - 8px); /* We need these extra 8px in Chrome to avoid double scroll bars. I have no idea why. */
}

.tech-tree-items {
    position: relative;
    width: max(min(40em, 50vw), 20em);
}

.tech-tree-item {
    position: absolute;
    color: gray;
    border: 1px solid gray;
    transform: translate(-50%, -50%);
    background-color: white;
    max-width: 8em;
}

.tech-tree-item.tech-tree-item-finished {
    color: black;
    border-color: black;
    background-color: lightgray;
}

.tech-tree-item.tech-tree-item-queued {
    color: darkblue;
    border-color: darkblue;
}

.tech-tree-item.tech-tree-item-available {
    color: green;
    border-color: green;
}

.tech-tree-lines {
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    width: 100%;
    height: 100%;
}

.tech-details {
    max-width: 30em;
    text-align: justify;
    margin-top: 1em;
    display: block;
}

.tech-details-progress {
    margin-top: 1em;
}

.tech-details-unlocks {
    margin-top: 2em;
    text-align: left;
}

.tech-details-unlocks-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.tech-details-unlocks-container > * {
    width: 64px;
    height: 64px;
}

.tech-details-unlocks-modifier {
    width: 100%;
    height: unset;
}
