.slots {
    display: grid;
    grid-template-columns: repeat(5, 64px);
    grid-auto-rows: 64px;
    gap: 8px;
    justify-items: stretch;
    align-items: stretch;
    user-select: none;
    margin-bottom: 1em;
    margin-left: 1px;
    margin-right: 1px;
}