.multi-platoon-ui
{
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    gap: 0.5em;
    justify-items: start;

    grid-template-rows: auto;
    grid-auto-rows: auto;
    overflow-y: auto;
}
